/* * Common styling */

.container {
  width: 99.5vw;
  display: flex;
}

.full-ht {
  height: 99.5vh;
}

.btn {
  color: white;
  width: 160px;
  height: 60px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-family: 'Roboto';
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.filled-btn {
  background-color: #2c8ee9;
}

.bordered-btn {
  background: none;
  border: 2px solid #63d5ff;
}

.filled-btn:hover {
  background-color: #0848d1;
}

.bordered-btn:hover {
  border: none;
  background-color: #7bdaff;
}

/* * Individual Styling */

.navbar {
  background: #003f6c;
  height: 9vh;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 20px 20px;
}

.links {
  display: flex;
  width: 50%;
  min-width: 700px;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.nav-btns-container {
  display: flex;
  width: 40%;
  min-width: 270px;
  height: 100%;
  margin-right: 1em;
  align-items: center;
  justify-content: space-around;
}

#nav-btns {
  width: 120px;
  height: 40px;
  border-radius: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  color: rgb(0, 162, 255);
}

#co-name {
  font-size: 1.2rem;
}

.company-name {
  display: flex;
  width: 20%;
  min-width: 250px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
}

.prudent-logo {
  width: 50px;
  height: 60px;
  margin-top: 10px;
  background: url("./Images/favicon.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.revamp-cont {
  justify-content: space-around;
  align-items: center;
}

.revamp-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.revamp-img{
  width: 600px;
  height: 600px;
  min-width: 400px;
  min-height: 420px;
  margin-top: 15%;
  background: url('./Images/construction.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.revamp-message p{
  font-family: 'Roboto';
}

.revamp-message h1{
  color: rgb(109, 109, 109);
  line-height: 2em;
}
